.choices {
	text-decoration: none;
	color: black;
	border: 2px solid black;
	font-size: large;
	text-transform: uppercase;
}

.choices:hover {
	text-decoration: none;
	background-color: black;
	color: white;
}
