.signup {
	padding-top: 20px;
}

.createAccountHeader {
	text-align: center;
	width: 100%;
}

.label {
	text-align: left;
	width: 100%;
}
