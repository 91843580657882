.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: #FFF;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover{
    color: #FFF;
    font-weight: 900;
}