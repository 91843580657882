.button {
	border: 2px solid black;
	background-color: transparent;
	color: #ff8c00;
	font-size: x-large;
	transition-duration: 0.4s;
}

.button:hover {
	background-color: black;
	color: white;
}
